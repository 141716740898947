
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Categories</h5>
      <div class="card">
        <div class="card-body">
          <div class="row gy-2">
            <div class="col-sm-12 col-lg-8 d-flex">
              <div class="me-2 d-none d-sm-inline">
                <label class="d-inline-flex mb-0 align-items-center">
                  <b-form-select class="form-select" v-model="paginate.perPage" :options="pageOptions"></b-form-select>
                </label>
              </div>
              <b-button variant="primary" class="me-2"
                @click.prevent="popupModalShow = true" type="button">
              <i class="ri ri-add-fill align-bottom"></i> <span class=""> Add Category </span></b-button>
            </div>
            <div class="col-sm-12 col-lg-4">
              <div class="form-inline text-sm-end">
                <div class="text-md-end d-lg-inline-flex mb-0 align-items-center">
                  <b-form-input v-model="searchQuery" type="search" placeholder="Search name..." class="form-control"></b-form-input>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="dataTables_paginate paging_simple_numbers">
            <ul class="pagination pagination-rounded">
              <b-pagination v-model="paginate.currentPage" :limit="paginate.limit" 
              :total-rows="searchedItems.length" :per-page="paginate.perPage"></b-pagination>
            </ul>
          </div>
        </div>
      </div>
      <div v-if="!isLoading" class="row">
        <div class="col-md-4 col-sm-6"
          v-for="(item, dIndex) of paginatedItems" :key="dIndex">
            <category-card :item="item">
              <template v-slot:actions>
                <a @click="initEdit(item)" href="javascript:void(0);" class="btn me-3 btn-primary btn-sm">
                  Edit</a>
                <a @click="deleteItem(item.id)" href="javascript:void(0);" class="btn btn-danger btn-sm">
                  Delete</a>
              </template>
            </category-card>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <is-loading />
        </div>
      </div>
    </div>
    <b-modal v-model="popupModalShow" hide-footer centered
      :title="editMode? 'Update category':'Add category'"
      title-class="fs-6" @hide="resetData()">
      <category-form v-if="popupModalShow" @closeMe="resetData()"
      :editMode="editMode" :editItem="editItem" />
    </b-modal>
  </div>
</template>

<script>
import CategoryForm from "@/components/forms/CategoryForm.vue"
import CategoryCard from "@/components/cards/CategoryCard.vue"
import IsLoading from '@/components/IsLoading.vue'

export default {
  name: "category-list",
  components:{
    CategoryForm, CategoryCard,
    IsLoading
  },
  data() {
    return {
      isLoading: true,
      popupModalShow: false,
      editMode: false,
      editItem: null,
      paginate:{
        currentPage: 1,
        perPage: 12,
        limit:3
      },
      pageOptions: [12, 25, 50, 100],
      searchQuery: '',
    }
  },
  computed: {
    items(){
      return this.$store.state.categoryList.categories
    },
    searchedItems(){
      return this.items.filter(i => i.name ? i.name.toLowerCase().includes(this.searchQuery.toLowerCase()) : false )
    },
    paginatedItems(){
      return this.searchedItems.slice(
        this.paginateStart,
        this.paginateEnd
      );
    },
    paginateStart(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage;
    },
    paginateEnd(){
      return (this.paginate.currentPage - 1) * this.paginate.perPage + this.paginate.perPage;
    },
  },
  methods: {
    deleteItem(itemId) {
      Swal.fire({
        title: "Are you sure?",
        text: "You are about to delete a plate size",
        reverseButtons:true,
        showCancelButton: true,
        confirmButtonColor: "#f1556c",
        cancelButtonColor: "#111",
        confirmButtonText: "Proceed",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/categories/${itemId}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$store.commit("categoryList/DELETE_CATEGORY", itemId)
            }
          })
        }
      });
    },
    initEdit(items){
      this.editMode = true;
      this.editItem = {...items}
      this.popupModalShow = true  
    },
    resetData(){
      this.editMode = false;
      this.editItem = null;
      this.popupModalShow = false;
    },
  },
  created(){
    this.$store.dispatch("categoryList/fetchCategories")
      .then((response => this.isLoading = false));
  },
}

</script>

