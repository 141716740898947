<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            <div class="col-md-6">
                <label for="inputName">
                    Name <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.name"
                    :class="{ 'is-invalid': v$.data.name.$error }"
                    id="inputName" name="name" class="form-control"
                    placeholder="Enter name" />
                <div v-for="(item, index) in v$.data.name.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mb-3">
                    <label class="form-label">
                        Slug <span class="text-danger">*</span>
                    </label>
                    <input type="text" v-model="data.slug" class="form-control"
                        name="price" :class="{ 'is-invalid': v$.data.slug.$error }" placeholder="Enter slug" />
                    <div v-for="(item, index) in v$.data.slug.$errors" :key="index"
                        class="invalid-feedback">
                        <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                </div>
            </div>
             <div class="col-6 col-md-4">
                <div class="mb-3">
                    <b-form-checkbox v-model="data.is_active" name="is_active" switch>
                        Active
                    </b-form-checkbox>
                </div>
            </div>
            <div class="col-6 col-md-4">
                <div class="mb-3">
                    <b-form-checkbox v-model="data.is_featured" name="is_featured" switch>
                        Is featured
                    </b-form-checkbox>
                </div>
            </div> 
            <div class="col-12 pt-3">
                <vue-dropzone id="categoryUploadDrop" ref="categoryUploadDrop"
                :use-custom-slot="true" :maxFiles="1">
                <div class="needsclick m-0">
                    <span class="bx bx-cloud-upload fs-1"></span>
                    <h6> {{editMode?'Add file to update':'Drop file or click to add'}}</h6>
                    <span class="text-muted fs-13">
                        (Recommended dimension (500x500 pixels))
                    </span>
                </div>
                </vue-dropzone>
            </div>
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="editMode? update() : create()"
                     type="button">{{editMode? 'Update':'Submit'}}</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import VueDropzone from "../VueDropzone.vue";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components:{
        VueDropzone,
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return{
            data: {
                name: "",
                slug: "",
                is_active: true,
                is_featured: false,
            },
        }
    },
    watch: {
        'data.name': function (name){
            if(this.editMode) return;
            this.data.slug = name.toString().normalize('NFD').replace(/[\u0300-\u036f]/g,'').replace(/\s+/g,'-').toLowerCase().replace(/&/g,'-and-').replace(/[^a-z0-9\-]/g,'').replace(/-+/g,'-').replace(/^-*/,'').replace(/-*$/,'');
        },
    },
    validations: {
        data:{
            name: {required: required},            
            slug: {required: required},            
        }
    },
    methods:{
        create() {
            this.v$.$validate().then(result =>{
                if (!result) return;
                if(!this.collectFiles().length){ this.alertError("Please a add image file"); return;}
                let formData = new FormData();
                formData.append('image_file', this.collectFiles()[0])
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/categories/create`, formData)
                .then((response) => {
                    this.$store.dispatch("changeLoaderValue", false)
                    if(response.data.success){
                        this.$store.commit("categoryList/ADD_CATEGORY", response.data.data.category)
                        this.resetForm();
                    }
                })
            })
        },
        update(){
             this.v$.$validate().then(result =>{
                if (!result) return;
                const formData = new FormData()
                if(this.collectFiles().length){
                    formData.append('image_file', this.collectFiles()[0])
                }
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/categories/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$store.commit("categoryList/UPDATE_CATEGORY", response.data.data.category)
                            this.resetForm()
                        }
                    })
            
            })
        },
        collectFiles(){
            let arrafile = [];
            if(this.$refs.categoryUploadDrop){
                arrafile = [...this.$refs.categoryUploadDrop.getAcceptedFiles()];
            }
            return arrafile
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = JSON.parse(JSON.stringify(this.editItem))
        }
    }
}
</script>