<template>
<div class="card card-height-100">
    <div class="card-body">
        <div class="row g-2">
            <div class="col-12"> 
                <img class="w-100" :src="absoluteUrl(item.image)" />
            </div>
            <div class="col-12">
                <h6 class="mt-2 mb-1"> {{item.name}}</h6>
                <span class="small">{{item.slug}}</span>
            </div>
        </div>
        <hr>
        <div class="">
            <div class="d-flex">
                <div class="form-check me-3">
                    <input disabled type="checkbox" :checked="item.is_active" class="form-check-input">
                    <label class="form-check-label" >Active</label>
                </div>
                <div class="form-check">
                    <input disabled type="checkbox" :checked="item.is_featured" class="form-check-input">
                    <label class="form-check-label" >Featured</label>
                </div>
            </div>
        </div>
        <hr>
        <slot name="actions"></slot>
    </div>
</div>

</template>

<script>
export default {
    props:{
        item:{
            type:Object,
            required: true
        }
    },      
}
</script>

<style>

</style>